import { Search } from '@mui/icons-material'
import { Box, Button, Stack } from '@mui/material'
import { format, parse, parseISO } from 'date-fns'
import { useRef, useState } from 'react'
import {
  BaseDateSelect,
  HandleBaseSelectInput,
} from 'src/component/base-component/base-date-select'
import { BaseDropDownInput } from 'src/component/base-component/base-dropdown'
import { BaseInput } from 'src/component/base-component/base-input'
import { BaseInputAutocomplete } from 'src/component/base-component/base-input-autocomplete'
import { BaseInputMoney } from 'src/component/base-component/base-input-money'
import { ModalFullContext } from 'src/component/modal/modal-provider'
import {
  CompanyAccountEntryOriginEnum,
  TCompanyAccountEntryCreate,
} from 'src/service/service-company'

const HistoricVariables = [
  {
    label: 'Data',
    value: 'data',
  },
  {
    label: 'Descrição',
    value: 'descricao',
  },
  {
    label: 'Valor',
    value: 'valor',
  },
  {
    label: 'Débito',
    value: 'debito',
  },
  {
    label: 'Crédito',
    value: 'credito',
  },
]

export const ModalClassifierEditEntry: React.FC<{
  data: TCompanyAccountEntryCreate
  handle: (item: TCompanyAccountEntryCreate) => Promise<void>
  companyId: string
  financialCategories: string[]
  creditAccounts: string[]
  debitAccounts: string[]
}> = ({
  handle,
  companyId,
  data,
  creditAccounts,
  debitAccounts,
  financialCategories,
}) => {
  const { HideModal } = ModalFullContext()
  const [financialCategory, setFiancialCategory] = useState<string>(
    data.financialCategory ?? '',
  )
  const [debit, setDebit] = useState<string>(data.debit ?? '')
  const [credit, setCredit] = useState<string>(data.credit ?? '')

  const [date, setDate] = useState<Date | undefined>(
    data.date
      ? (() => {
          const parsedDate = parse(data.date, 'dd/MM/yyyy', new Date())
          // Zerando as horas para evitar problemas de fuso horário
          parsedDate.setHours(0, 0, 0, 0)
          return parsedDate
        })()
      : undefined,
  )

  const DateInitialRef = useRef<HandleBaseSelectInput>(null)
  const [value, setValue] = useState<string>(data.value ?? '')
  const [description, setDescription] = useState<string>(data.description ?? '')
  const [historic, setHistoric] = useState<string>(data.historic ?? '')

  const handleSelectDate = (e: Date | undefined) => {
    setDate(e)
  }

  const handleCreate = () => {
    if (!date || !value) return
    const amount = Math.abs(Number(value?.replace(/[^\d-]/g, '')) / 100)
    const validatedNumber = Number(value?.replace(/[^\d-]/g, '')) / 100

    const dataToSend = {
      id: data.id,
      date: format(date.toUTCString(), 'yyyy-MM-dd HH:mm:ss.SSS'),
      value: amount.toString(),
      method: validatedNumber <= 0 ? 'DEBITO' : 'CREDITO',
      bankTransferMethod: validatedNumber <= 0 ? 'DEBITO' : 'CREDITO',
      credit,
      debit,
      description,
      financialCategory,
      historic,
      vendor: undefined,
    }

    handle && handle(dataToSend)
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: 'max-content max-content',
      }}
    >
      <Box
        sx={{
          width: '600px',

          borderRadius: '16px',
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap="18px"
        >
          <BaseInputAutocomplete
            companyId={companyId}
            options={financialCategories}
            variant="debits"
            error=""
            label="Cat. financeira"
            fullWidth
            setState={(e) => setFiancialCategory(e)}
            value={financialCategory}
            containerStyle={{ width: '100%' }}
            iconEnd={<Search />}
            inputVariant="bordered"
          />
          <BaseInputAutocomplete
            companyId={companyId}
            options={debitAccounts}
            variant="debits"
            error=""
            label="Conta débito"
            fullWidth
            setState={(e) => setDebit(e)}
            value={debit}
            containerStyle={{ width: '100%' }}
            iconEnd={<Search />}
            inputVariant="bordered"
          />
          <BaseInputAutocomplete
            companyId={companyId}
            options={creditAccounts}
            variant="debits"
            error=""
            label="Conta crédito"
            fullWidth
            setState={(e) => setCredit(e)}
            value={credit}
            containerStyle={{ width: '100%' }}
            iconEnd={<Search />}
            inputVariant="bordered"
          />
        </Stack>

        <Box width="100%" borderBottom="dashed 1px #777C84" marginTop="20px" />

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap="18px"
          marginTop="18px"
        >
          <BaseDateSelect
            title="Data"
            onSelect={(e) => handleSelectDate(e)}
            ref={DateInitialRef}
            disabled={data.origin === CompanyAccountEntryOriginEnum.TRANSFER}
            containerStyle={{ width: '50%' }}
            buttonStyle={{ width: '100%' }}
            useCalendarPosition={false}
            selectedDate={date}
          />

          <BaseInputMoney
            label="Valor"
            type="text"
            fullWidth={true}
            disabled={data.origin === CompanyAccountEntryOriginEnum.TRANSFER}
            value={value}
            setState={(e) => setValue(e)}
            error=""
            containerStyle={{ width: '50%' }}
          />
        </Stack>

        <Stack direction="column" gap="18px" marginY="18px">
          <BaseInput
            label="Descrição"
            fullWidth
            setState={(e) => setDescription(e)}
            error=""
            value={description}
            containerStyle={{ width: '100%' }}
          />

          <BaseDropDownInput
            options={HistoricVariables}
            buttonLabel="Histórico"
            showInternalLabel
            setState={(e) => setHistoric(e?.join('|'))}
            error=""
            variant="outlined"
            value={historic ? historic?.split('|') : undefined}
          />
        </Stack>
      </Box>

      {/* Footer */}

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'max-content max-content',
          gap: '14px',
          justifySelf: 'end',
          padding: '0 4px',
        }}
      >
        <Button
          variant="outlined"
          onClick={HideModal}
          sx={{ width: '104px', height: '40px' }}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            handleCreate()
          }}
          sx={{ width: '240px', height: '40px' }}
        >
          Editar lançamento
        </Button>
      </Box>
    </Box>
  )
}
